















































import { defineComponent, PropType } from "@nuxtjs/composition-api";
import { SfPrice } from "@storefront-ui/vue";
import type { ProductWithCommonProductCardProps } from "~/modules/catalog/category/components/views/useProductsWithCommonCardProps";

export default defineComponent({
  name: "CategoryProductPrice",
  components: {
    SfPrice,
  },
  props: {
    product: {
      type: Object as PropType<ProductWithCommonProductCardProps>,
      required: true,
    },
  },
});
