import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { UseClerkError, UseClerkEventsInterface } from './useClerkEvents';

export function useClerkEvents(id?: string): UseClerkEventsInterface {
    const context = useContext();
    const loading = ref(false);
    const error = ref<UseClerkError>({
        trackClick: null,
        trackProduct: null,
        trackSale: null,
    });

    //---------track click--------------
    const trackClick = async (productId: string): Promise<void> => {
        try {
            const cookieRes = context.$cookies.get('clerk-tracking-id')

            const requestObject = {
                key: context.app.$config.vsfClerkPublicKey,
                product: productId,
                visitor: cookieRes,
                labels: ['Search']
            }

            loading.value = true;
            await context.app.$clerkIoApi.get('log/click', {
                params: requestObject
            })
        }
        catch (err) {
            error.value.trackClick = err;
            Logger.error(`useClerkEvents/${id}/trackClick`, err);
        } finally {
            loading.value = false;
        }
    };

    //---------track product--------------
    const trackProduct = async (productId: string): Promise<void> => {
        try {
            const cookieRes = context.$cookies.get('clerk-tracking-id')

            const requestObject = {
                key: context.app.$config.vsfClerkPublicKey,
                product: productId,
                visitor: cookieRes,
                labels: ['Search']
            }

            await context.app.$clerkIoApi.get('log/product', {
                params: requestObject
            })
        }
        catch (err) {
            error.value.trackProduct = err;
            Logger.error(`useClerkEvents/${id}/trackProduct`, err);
        } finally {
            loading.value = false;
        }
    };

    //---------track sale--------------
    const trackSale = async (productArray: any, orderId: string): Promise<void> => {
        try {
            const cookieRes = context.$cookies.get('clerk-tracking-id')

            const requestObject = {
                key: context.app.$config.vsfClerkPublicKey,
                products: productArray,
                sale: orderId,
                visitor: cookieRes
            }
            await context.app.$clerkIoApi.get('log/sale', {
                params: requestObject
            })
        }
        catch (err) {
            error.value.trackSale = err;
            Logger.error(`useClerkEvents/${id}/trackSale`, err);
        } finally {
            loading.value = false;
        }
    };

    //---------map email--------------
    const trackEmail = async (userEmail: string): Promise<void> => {
        try {
            const cookieRes = context.$cookies.get('clerk-tracking-id')

            const requestObject = {
                key: context.app.$config.vsfClerkPublicKey,
                email: userEmail,
                visitor: cookieRes
            }
            await context.app.$clerkIoApi.get('log/email', {
                params: requestObject
            })
        }
        catch (err) {
            error.value.trackSale = err;
            Logger.error(`useClerkEvents/${id}/trackEmail`, err);
        } finally {
            loading.value = false;
        }
    };
    return {
        trackClick,
        trackProduct,
        trackSale,
        trackEmail
    };
}

export default useClerkEvents;