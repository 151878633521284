








































import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  toRefs,
} from "@nuxtjs/composition-api";
import { SfProductCard } from "@storefront-ui/vue";
import { useImage } from "~/composables";
import type { Product } from "~/modules/catalog/product/types";

import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import CategoryProductPrice from "~/modules/search/views/CategoryProductPrice.vue";
import { useProductsWithCommonProductCardProps } from "~/modules/search/views/useProductsWithCommonCardProps";
import useClerkEvents from "~/modules/clerkTrack/composables/useClerkEvents";

export default defineComponent({
  components: {
    CategoryProductPrice,
    SfProductCard,
    SkeletonLoader,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    pricesLoaded: Boolean,
    loading: Boolean,
  },
  emits: ["click:wishlist", "click:add-to-cart"],
  setup(props) {
    const {
      imageSizes: { productCard: imageSize },
    } = useImage();
    const { products } = toRefs(props);
    const { productsWithCommonProductCardProps } =
      useProductsWithCommonProductCardProps(products);
    const { trackClick } = useClerkEvents();

    const productClicked = (elm) => {
      const parentNode = elm.target.closest("[data-selector=product-card]");
      if (parentNode) {
        trackClick(parentNode.getAttribute("data-sku"));
      }
    };

    onMounted(async () => {
      const elm = window.document.querySelector("[data-selector=product-card]");
      window.addEventListener("click", productClicked);
    });

    onUnmounted(() => {
      window.removeEventListener("click", productClicked);
    });

    return {
      imageSize,
      productsWithCommonProductCardProps,
      productClicked,
    };
  },
});
